export const COMPUTER_SKILLS_START = [
  {
    id: "1",
    title: "React",
    completed: 0,
  },
  {
    id: "2",
    title: "React-Native",
    completed: 0,
  },
  {
    id: "3",
    title: "JavaScript/HTML/CSS",
    completed: 0,
  },
  {
    id: "4",
    title: "R-Studio",
    completed: 0,
  },
  {
    id: "5",
    title: "SQL/Mongo DB",
    completed: 0,
  },
  {
    id: "6",
    title: "Python",
    completed: 0,
  }
];

export const COMPUTER_SKILLS = [
  {
    id: "1",
    title: "React",
    completed: 85,
  },
  {
    id: "2",
    title: "React-Native",
    completed: 60,
  },
  {
    id: "3",
    title: "JavaScript/HTML/CSS",
    completed: 90,
  },
  {
    id: "4",
    title: "R-Studio",
    completed: 95,
  },
  {
    id: "5",
    title: "SQL/Mongo DB",
    completed: 70,
  },
  {
    id: "6",
    title: "Python",
    completed: 30,
  }
];


export const REPORTING_SKILLS_START = [
    {
      id: "1",
      title: "Power BI",
      completed: 0,
    },
    {
      id: "2",
      title: "Tableau",
      completed: 0,
    },
    {
      id: "3",
      title: "Microsoft Excel",
      completed: 0,
    },
    {
      id: "4",
      title: "Project Management",
      completed: 0,
    },
    {
      id: "5",
      title: "Data Science",
      completed: 0,
    },
    {
      id: "6",
      title: "Statistics",
      completed: 0,
    }
  ];
  
export const REPORTING_SKILLS = [
    {
      id: "1",
      title: "Power BI",
      completed: 95,
    },
    {
      id: "2",
      title: "Tableau",
      completed: 80,
    },
    {
      id: "3",
      title: "Microsoft Excel",
      completed: 98,
    },
    {
      id: "4",
      title: "Project Management",
      completed: 75,
    },
    {
      id: "5",
      title: "Data Science",
      completed: 80,
    },
    {
      id: "6",
      title: "Statistics",
      completed: 100,
    }
  ];
  



