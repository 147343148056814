const FeaturesData = [{
    id:1,
    title:"Website Design",
    description: "Create easy to use , aesthetically pleasing websites that suits the user group and brand of the company"
  },{
    id:2,
    title:"Dashboard Creation",
    description: "Build out clear, interactive, user friendly dashboards that bring insight and visibility  to the business"
  },
  {
    id:3,
    title:"App Development",
    description: "Design, create, deploy and update programs for particular operating systems"
  },
  {
    id:4,
    title:"Statistical Analytics",
    description: "Taking complex data and producing analytics/ reporting that helps drive business discussions"
  },
  {
    id:5,
    title:"Project Management",
    description: "Planning, budgeting,  and monitoring  the project with the use of various project management methodologies"
  },
  {
    id:6,
    title:"Automation",
    description: "Automate processes and increase efficiency within the business that will lead to scalability and growth."
  }]

  export default FeaturesData;