import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Timeline from "@mui/lab/Timeline";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import CodeIcon from "@mui/icons-material/CodeOutlined";
import TimelineIcon from "@mui/icons-material/TimelineOutlined";
import Typography from "@mui/material/Typography";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";

import { EXPERIENCE } from "../../../../../data/experience-data";
import { lengthOfService } from "../../../../../util/functions";

const ExperienceSmall = () => {
  return (
    <Container
      id="Resume"
      sx={{ display: { xs: "flex", s: "none", md: "none" }, p: 3 }}
      maxWidth="xl"
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Timeline
          sx={{
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0,
            },
          }}
        >
          {EXPERIENCE.map((item) => (
            <TimelineItem key={item.id} sx={{ margin: "15px 0px" }}>
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot sx={{ color: "#6E8278" }} variant="outline">
                  {item.icon === "Data" ? <TimelineIcon /> : <CodeIcon />}
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ py: "12px", px: 2 }}>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    color: "#6E8278",
                    fontSize: "25px",
                  }}
                  component="p"
                >
                  {item.title}
                </Typography>
                <Typography sx={{ fontSize: "16px", color: "#BCBCBC" }}>
                  {item.startDate.toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "short",
                  })}
                  -
                  {item.lastDate.toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "short",
                  })}
                </Typography>
                <Typography sx={{ fontSize: "16px", color: "#BCBCBC" }}>
                  {lengthOfService(item.startDate, item.lastDate)}
                </Typography>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </Grid>
    </Container>
  );
};

export default ExperienceSmall;
