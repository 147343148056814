import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";

import Welcome from "./components/Welcome/Welcome";
import HomeImg from "../../images/HomeImages/HomeImage.png"

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  color: theme.palette.text.secondary,
}));

const Home = () => {
  return (
    <>
    <Container id="Home" sx={{p:3}} maxWidth="xl">
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} s={12} md={12} lg={6}>
            <Item elevation={0}>
              <Welcome />
            </Item>
          </Grid>
          <Grid item  xs={12} s={12} md={12} lg={6}>
            <Item  elevation={0} >
              <img style={{width:"100%"}} src={HomeImg} alt="Computers"/>
            </Item>
          </Grid>
        </Grid>
      </Box>
    </Grid>
    </Container>
    </>
  );
};

export default Home;
