import moment from "moment";

// Length of Service Year and Mos
export function lengthOfService(startDate, endDate) {
  var date1 = moment(startDate);
  var date2 = moment(endDate);

  var diffDuration = moment.duration(date2.diff(date1));
  var years = diffDuration.years() + " yrs ";
  var months = diffDuration.months() + " mos";
  var message = years + months;

  return message;
}

// Total Experience
export function totalExperience() {
  var date1 = moment(new Date(2014, 6, 1));
  var date2 = moment(new Date());

  var diffDuration = moment.duration(date2.diff(date1));
  var years = diffDuration.years();
  var message = years + " + YEARS ";

  return message.toUpperCase();
}
