import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";

import ProgressBar from "../../../../../layout/ProgressBar";
import { REPORTING_SKILLS_START } from "../../../../../data/skills-data";
import { REPORTING_SKILLS } from "../../../../../data/skills-data";

const ReportingSkills = () => {
  const [reportingSkills, setReportingSkills] = useState(
    REPORTING_SKILLS_START
  );

  useEffect(() => {
    setInterval(() => setReportingSkills(REPORTING_SKILLS), 500);
  }, []);

  return (
    <Grid item xs={12} s={12} md={6} lg={6}>
      <Typography
        component={"span"}
        sx={{
          color: "white",
          fontSize: { xs: 26, s: 26, md: 30, lg: 36 },
          fontWeight: "bold",
        }}
      >
        Reporting Tools
      </Typography>
      <Typography
        component={"p"}
        sx={{
          color: "#6E8378",
          fontSize: { xs: 12, s: 14, md: 16 },
          fontWeight: "bold",
        }}
      >
        SKILLS
      </Typography>
      {reportingSkills.map((skill) => (
        <div style={{ width: "100%" }} key={skill.id}>
          <p style={{ marginLeft: 10, marginBottom: "0", color: "grey" }}>
            {skill.title}
          </p>
          <ProgressBar completed={skill.completed} />
        </div>
      ))}
    </Grid>
  );
};

export default ReportingSkills;
