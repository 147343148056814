import { useTypewriter, Cursor } from "react-simple-typewriter";
import { Typography } from "@mui/material";

import SocialMedia from "../Welcome/components/SocialMedia";
import SkillsLogo from "./components/SkillsLogo";

const Welcome = () => {
  const { text } = useTypewriter({
    words: [
      " Data Scientist.",
      " Developer.",
      " Statistician.",
      " Problem Solver.",
    ],
    loop: 0,
    typeSpeed: 100,
    deleteSpeed: 100,
    delaySpeed: 2000,
  });

  return (
    <>
      <Typography sx={{ py: 1, fontSize: {s:16 ,md: 18 }}}>WELCOME TO MY WORLD</Typography>
      <Typography
      component ={'span'}
        sx={{
          color: "white",
          py: 1,
          fontSize: {
            xl: "60px",
            lg: "50px",
            md: "40px",
            s: "35px",
            xs: "35px",
          },
        }}
      >
        Hi, I'm{" "}
        
        <Typography variant="span" sx={{ color: "#6E8378" }}>
          Garrett Fermo
        </Typography>
        <Typography
          sx={{
            fontSize: {
              xl: "45px",
              lg: "35px",
              md: "30px",
              s: "25px",
              xs: "25px",
            },
          }}
        >
          a {text}
          <Cursor cursorStyle="_" />
        </Typography>
      </Typography>

      <Typography sx={{ py: 2, fontSize: { s:14, md:15, lg:17, xl:17} }}>
        I use my knowledge and experience with Data to help create and improve
        business processes. Anywhere from the creation of Dashboards, Websites,
        ETL Reporting, Data management, and Software Development. The goal is to
        make something that may be extremely complex and challenging, very
        simple and clean to consume within your business.
      </Typography>

      <SocialMedia />
      <SkillsLogo />
    </>
  );
};

export default Welcome;
