import { Grid} from "@mui/material";

import classes from "./SocialMediaFooter.module.css";

//Social Media Icons
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import YouTubeIcon from "@mui/icons-material/YouTube";

const SocialMediaFooter = () => {
  return (
    <>
      <Grid
        container
        sx={{
          pl: 0,
          width: "auto",
          justifyContent: "center",
          marginTop:"-15px"
        }}
      >
        <Grid item xs="auto">
          <div style={{ paddingLeft: "18px" }} className={classes.container}>
            <ul className={classes.container__ul}>
              <li className={classes.container__ul__li}>
                <a
                  className={classes.container__ul__li__a}
                  href="https://www.instagram.com/fermotech/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <InstagramIcon style={{ color: "white" }} />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/in/garrett-fermo-2b063289/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <LinkedInIcon style={{ color: "white" }} />
                </a>
              </li>
              <li>
                <a
                  href="https://github.com/garrettcfermo"
                  target="_blank"
                  rel="noreferrer"
                >
                  <GitHubIcon style={{ color: "white" }} />
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <YouTubeIcon style={{ color: "white" }} />
                </a>
              </li>
            </ul>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default SocialMediaFooter;
