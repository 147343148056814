import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ContactImg from "../../images/ContactImages/ContactUs.png";

const Contact = () => {
  return (
    <>
      <Container id="Contact" sx={{ p: 3, marginTop: "50px" }} maxWidth="xl">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          {/* Introductions */}
          <Box sx={{ textAlign: "center" }}>
            <Typography
              component={"p"}
              sx={{
                color: "#6E8378",
                fontSize: { s: 16, md: 18 },
                fontWeight: "bold",
              }}
            >
              CONNECT WITH ME
            </Typography>
            <Typography
              component={""}
              sx={{
                color: "white",
                py: 1,
                fontSize: {
                  xl: "55px",
                  lg: "50px",
                  md: "45px",
                  s: "45px",
                  xs: "45px",
                },
              }}
            >
              Let's Talk Tech
            </Typography>
          </Box>
        </Grid>

        <Grid container direction="row" justifyContent="center"  >
          <Grid sx={{ textAlign: "center" }} item xs={12} s={12} md={12} lg={6}>
            <Typography
              sx={{
                color: "#6E8378",
                fontSize: {xs:"22px", s:"22px", md:"30px", lg:"30px"},
                textAlign: "center",
                marginTop: "35px",
              }}
            >
              I am available for freelance and contract work. Please fill out
              the information below and I will get back to you as soon as
              possible. Thank you!
            </Typography>
            <img
              style={{ width: "100%", height: "auto", marginTop:"20px" }}
              src={ContactImg}
              alt="ContactUs"
            />
          </Grid>
          <Grid
            item
            xs={12}
            s={12}
            md={12}
            lg={6}
          >
            <iframe
              title="jotform1"
              style={{ height: "1100px", width: "100%", border: "none" }}
              src={"https://form.jotform.com/223390809578164"}
            ></iframe>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Contact;
