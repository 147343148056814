export const EXPERIENCE = [
  {
    id: 1,
    title: "Data Scientist",
    icon: "Code",
    companyName: "Ingram Micro",
    startDate: new Date(2021, 2, 1),
    lastDate: new Date(),
    description: [
      "Work in the human resources department producing complex dashboards that help drive business decisions, data insights and improvements to a global company of over 40K employees",
      "Talent Acquisition Dashboards",
      "Attrition/Employee Management Dashboards",
      "Compensation Dashboards",
      "Compensation Dashboards",
      "Manage and maintenance a dashboard environment in Power BI that integrates with all of the companies security and infrastructures",
      "Developed the ETL process and data structure from the data lake to the dashboard environment, that handles daily and weekly refresh rates",
    ],
  },
  {
    id: 2,
    title: "Software Developer/Tableau Developer",
    icon: "Code",
    companyName: "Profit Recovery Partners",
    startDate: new Date(2019, 0, 1),
    lastDate: new Date(2021, 1, 31),
    description: [
      "Developed Tableau dashboards to communicate with back-end servers in order to provide real-time updated information to clients",
      "Manage and maintenance 300 Tableau dashboards for over 100 clients",
      "Manage and Upgraded the Tableau Server from V10.2 to V2020.1",
      "Tested all software to alleviate bugs and troubleshoot issues prior to applications going live",
      "Collaborated with internal technology teams and third party partners to define, design and deliver on user stories and customer demand",
      "Developed production, test and beta websites using various software programs",
      "Built custom software solutions for internal departments to help improve workflow",
    ],
  },
  {
    id: 3,
    title: "Logistics Supervisor/Specialist",
    icon: "Data",
    companyName: "Profit Recovery Partners",
    startDate: new Date(2016, 7, 1),
    lastDate: new Date(2018, 11, 31),
    description: [
      "Responsible for leading a team of 3 employees and delegating responsibilities",
      "Motivating staff on career goals, conducting annual reviews and monthly updates to ensure employee compliance to company policies and procedures ",
      "Developed and defined Practice Group and Company processes and procedures",
      "Transitioned outdated analytical tools to Power BI (Dashboards), to help with the quantitative analytics",
      "Resulting in better automation of the quarterly business reports and handling of big data",
      "Increasing productivity by 50%",
      "Resulting in moving from quarterly reports to weekly/daily reports",
      "Executed a new folder infrastructure for the entire company. This increased workflow by 15% due to easier accessibility and organization as well as document security",
      "Implemented the use of Toggl, a time tracking software to help the entire company see time metrics as a whole",
    ],
  },
  {
    id: 4,
    title: "Business Analyst/Senior Business Analyst",
    icon: "Data",
    companyName: "Profit Recovery Partners",
    startDate: new Date(2015, 7, 1),
    lastDate: new Date(2016, 6, 31),
    description: [
      "Designed and created automated quarterly business report templates for quantitative analytics that decreased the processing time from 4 hours to 1 hour",
      "This covered over 35 different expense areas",
      "Managed over 100 quarterly business reports a month",
      "Trained over 20 employees with lectures on creating and understanding quantitative analytics",
      "Training resulted in decreasing the analytical skills learning curve from an average of 6 months to about 2 months",
      "Developed a prioritization dashboard to help determine what quarterly business reports needed to be completed based on client meeting dates and data obtainment requirements. This helped the Business Analytics Team meet their goals and avoid reoccurring bottle necks",
      "The dashboard resulted in an increase of overall on time deliverables from 75% to 97%",
      "Created a Time Tracking program to help manage work flow insuring that the team was on track with the quarterly business reports and deliverables",
      "Bringing the overall team average of 40 hours a week in overtime down to roughly 0 hours",
    ],
  },
  {
    id: 5,
    title: "Associate Analyst/Logistic Analyst",
    icon: "Data",
    companyName: "Profit Recovery Partners",
    startDate: new Date(2014, 6, 1),
    lastDate: new Date(2015, 6, 31),
    description: [
      "Managed over 80 client’s quarterly business reports, day to day ad-hoc requests, and vendor relationships",
      "Designed and created an automated quarterly business report template and work product that brought the processing time down from 10 hours to 1 hour",
      "These templates helped manage over $50 million in confirmed savings and $10 million in new savings opportunities for Small Parcel, Courier Services, Sedan Services and Freight",
      "Lead project manager for the creation of the small parcel data warehouse with the help of the business intelligence department. Consisting of system integration, data integration, ETL, data mapping and developing a data layout to help drive the small parcel dashboard thru Tableau",
      "Resulting in storing over ~$200 million in expense for 80 clients",
      "Creating a quicker processing time to complete the quarterly business reports",
      "Implemented cost reduction and behavioral change strategies for new customers resulting in over $3 million in potential annual savings",
    ],
  },
];
