import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import CodeIcon from "@mui/icons-material/CodeOutlined";
import TimelineIcon from "@mui/icons-material/TimelineOutlined";
import Typography from "@mui/material/Typography";
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from "@mui/lab/TimelineOppositeContent";

import { EXPERIENCE } from "../../../../../data/experience-data";
import { lengthOfService } from "../../../../../util/functions";

const ExperienceBig = () => {
  return (
    <Container
      id="Resume"
      sx={{ display: { xs: "none", s: "flex", md: "flex" }, p: 3 }}
      maxWidth="xl"
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Timeline
          sx={{
            [`& .${timelineOppositeContentClasses.root}`]: {
              flex: 0.2,
            },
          }}
        >
          {EXPERIENCE.map((item) => (
            <TimelineItem key={item.id} sx={{ margin: "30px 0px" }}>
              <TimelineOppositeContent
                sx={{ m: "auto 0" }}
                variant="body2"
                color="text.secondary"
              >
                <Typography>
                  {item.startDate.toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "short",
                  })}
                  -
                  {item.lastDate.toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "short",
                  })}
                </Typography>
                {lengthOfService(item.startDate, item.lastDate)}
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot sx={{ color: "#6E8278" }} variant="outline">
                  {item.icon === "Data" ? <TimelineIcon /> : <CodeIcon />}
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ py: "12px", px: 2 }}>
                <Typography
                  sx={{
                    fontSize: "35px",
                    fontWeight: "bold",
                    color: "#6E8278",
                  }}
                  component="span"
                >
                  {item.title}
                </Typography>
                <ul sx={{ fontSize: "16px" }}>
                  {item.description.map((item) => (
                    <li
                      style={{ color: "#BCBCBC", width: "auto" }}
                      component="span"
                    >
                      {item}
                    </li>
                  ))}
                </ul>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </Grid>
    </Container>
  );
};

export default ExperienceBig;
