import Grow from "@mui/material/Grow";
import { Typography } from "@mui/material";
import VizSensor from "react-visibility-sensor";
import { useState } from "react";

import WebDesignImg from "../../../images/FeaturesImages/Web_Design.png";
import DashCreationImg from "../../../images/FeaturesImages/Dashboard_Creation.png";
import AppDevImg from "../../../images/FeaturesImages/App_Development.png";
import StatsImg from "../../../images/FeaturesImages/Statistical_Analytics.png";
import ProMangImg from "../../../images/FeaturesImages/Project_Management.png";
import AutoImg from "../../../images/FeaturesImages/Automation.png";

const imgNameFun = (name) => {
  switch (name) {
    case "Website Design":
      return WebDesignImg;
    case "Dashboard Creation":
      return DashCreationImg;
    case "App Development":
      return AppDevImg;
    case "Statistical Analytics":
      return StatsImg;
    case "Project Management":
      return ProMangImg;
    case "Automation":
      return AutoImg;
    default:
      return "";
  }
};

const FeatureCard = (props) => {
  const [active, setActive] = useState(false);

  return (
    <VizSensor
      onChange={(isVisible) => {
        setActive(isVisible);
      }}
    >
      <Grow in={active} timeout={2000}>
        <div
          ref=""
          style={{
            width: "auto",
            height: "350px",
            borderRadius: "15px",
            border: "7px solid rgb(110,131,120)",
            borderStyle: "double",
            margin: "1px",
            padding: "10px",
          }}
        >
          <img
            style={{ width: "120px", paddingTop: "15px" }}
            src={imgNameFun(props.title)}
            alt={props.title}
          />
          <Typography
            sx={{ fontSize: "28px", p: " 0 5px", fontWeight: "bold" }}
          >
            {props.title}
          </Typography>
          <Typography sx={{ p: "15px", fontSize: "14px" }}>
            {props.description}
          </Typography>
        </div>
      </Grow>
    </VizSensor>
  );
};

export default FeatureCard;
