import NavBar from "./layout/NavBar";
import Footer from "./layout/Footer/Footer";

import Home from "./components/Home/Home";
import Features from "./components/Features/Features";
import Portfolio from "./components/Portfolio/Portfolio";
import Resume from "./components/Resume/Resume";
import Contact from "./components/Contact/Contact";
import { Routes, Route } from "react-router-dom";

const App = () => {
  return (
    <>
      <Routes>
        <Route
          exact
          path="/"
          element={[
            <NavBar />,
            <Home />,
            <Features />,
            <Portfolio />,
            <Resume />,
            <Contact />,
            <Footer />
          ]}
        />
      </Routes>
    </>
  );
};

export default App;
