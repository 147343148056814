import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import ComputerSkills from "./components/ComputerSkills";
import ReportingSkills from "./components/ReportingSkills";

const Skills = () => {
  return (
    <Container id="Resume" sx={{ p: 3 }} maxWidth="xl">
      <Grid container spacing={10}>
        <ComputerSkills />
        <ReportingSkills />
      </Grid>
    </Container>
  );
};

export default Skills;
