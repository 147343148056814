import { Grid, Typography } from "@mui/material";

import classes from "./SkillsLogo.module.css";

//Skill Logos
import RStudioIcon from "../../../../../images/HomeImages/SkillsLogos/RStudioLogo.png";
import ReactIcon from "../../../../../images/HomeImages/SkillsLogos/ReactLogo.png";
import NodeJsIcon from "../../../../../images/HomeImages/SkillsLogos/NodeJsLogo.png";
import PowerBiIcon from "../../../../../images/HomeImages/SkillsLogos/PowerBiLogo.png";

const SkillsLogo = () => {
  return (
    <>
      <Grid
        container
        sx={{
          pt: { xs: 4, s: 4, md: 4, lg: 4, xl: 12 },
          pl: 0,
          width: "auto",
          justifyContent: {
            xs: "center",
            s: "center",
            md: "center",
            lg: "left",
          },
        }}
      >
        <Grid item xs="auto">
          <Typography sx={{ pb: 1, pl: { xs: 13, md: 13, lg: 0 } }}>
            BEST SKILLED IN
          </Typography>
          <div style={{ paddingLeft: "18px" }} className={classes.container}>
            <ul>
              <li>
                <a
                  href="https://www.rstudio.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={RStudioIcon} alt="R Studio Icon" />
                </a>
              </li>
              <li>
                <a href="https://reactjs.org/" target="_blank" rel="noreferrer">
                  <img src={ReactIcon} alt="React Icon" />
                </a>
              </li>
              <li>
                <a
                  href="https://nodejs.org/en/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={NodeJsIcon} alt="Node JS Icon" />
                </a>
              </li>
              <li>
                <a
                  href="https://powerbi.microsoft.com/en-us/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={PowerBiIcon} alt="Power BI Icon" />
                </a>
              </li>
            </ul>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default SkillsLogo;
