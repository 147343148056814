import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import CodeIcon from "@mui/icons-material/CodeOutlined";

import CalculateIcon from '@mui/icons-material/CalculateOutlined';
import Typography from "@mui/material/Typography";
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from "@mui/lab/TimelineOppositeContent";

import { EDUCATION } from "../../../../../data/education-data";

const EducationBig = () => {
  return (
    <Container
      id="Resume"
      sx={{ display: { xs: "none", s: "flex", md: "flex" }, p: 0 }}
      maxWidth="xl"
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Timeline
          sx={{
            [`& .${timelineOppositeContentClasses.root}`]: {
              flex: 0.2,
            },
          }}
        >
          {EDUCATION.map((item) => (
            <TimelineItem key={item.id} sx={{ margin: "30px 0px" }}>
              <TimelineOppositeContent
                sx={{ m: "auto 0" }}
                variant="body2"
                color="text.secondary"
              ></TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot sx={{ color: "#6E8278" }} variant="outline">
                  {item.icon === "Code" ? <CodeIcon /> : <CalculateIcon />}
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ py: "12px", px: 2 }}>
                <Typography
                  sx={{
                      fontSize: "39px",
                      fontWeight: "bold",
                      color: "#6E8278",
                    }}
                    >
                  {item.title}
                </Typography>
                <Typography
                  sx={{
                      fontSize: "20px",
                    }}
                    >
                {item.schoolName}
                </Typography>
                    {item.gradutionYear}
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </Grid>
    </Container>
  );
};

export default EducationBig;
