import { useState } from "react";
import { styled } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { Typography, Box } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import Education from "./components/Education/Education";
import Experience from "./components/Experience/Experience";
import Skills from "./components/Skills/Skills";
import { totalExperience } from "../../util/functions";

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-flexContainer": {
    flexWrap: "wrap",
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    border: "1px solid #6E8378",
    borderRadius: "20px",
    margin: "10px",
    padding: "30px",
    minWidth: "100px",
    flex: 1,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),

    color: "#6E8378",
    "&.Mui-selected": {
      color: "white",
      fontWeight: "bold",
      backgroundColor: "#6e8378",
    },
  })
);

const Resume = () => {
  const [value, setValue] = useState(1);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
    <Container id="Resume" sx={{ p: 3, marginTop: "80px" }} maxWidth="xl">
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        {/* Introductions */}
        <Box sx={{ textAlign: "center" }}>
          <Typography
            component={"span"}
            sx={{
              color: "#6E8378",
              fontSize: { s: 16, md: 18 },
              fontWeight: "bold",
            }}
          >
            {totalExperience()} OF EXPERINCE
          </Typography>
          <Typography
            component={""}
            sx={{
              color: "white",
              py: 1,
              fontSize: {
                xl: "60px",
                lg: "50px",
                md: "40px",
                s: "35px",
                xs: "35px",
              },
            }}
          >
            My Resume
          </Typography>
        </Box>
      </Grid>

      {/* Tab Selection  */}
      <Box sx={{ width: "100%" }}>
        <StyledTabs
          value={value}
          onChange={handleChange}
          aria-label="styled tabs example"
          centered
        >
          <StyledTab label="Experience" />
          <StyledTab label="Skills" />
          <StyledTab label="Education" />
        </StyledTabs>
      </Box>

      {/* Skills - Experience - Education Section */}
      {value === 0 && <Experience />}
      {value === 1 && <Skills />}
      {value === 2 && <Education />}
      
    </Container>
    <hr style={{border:"1px solid #272727", marginTop:"50px"}} />
    </>
  );
};

export default Resume;
