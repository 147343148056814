import ExperienceBig from "./components/ExperienceBig";
import ExperienceSmall from "./components/ExperienceSmall"

const Experience = () => {
  return (
    <>
      <ExperienceBig />
      <ExperienceSmall />
    </>
  );
};

export default Experience;
