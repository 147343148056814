import { Typography, Container, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import SocialMediaFooter from "../Footer/components/SocialMediaFooter";

import logo from "../../images/Footer/logo.png";

const Footer = () => {
  return (
    <Box sx={{ p: 2, backgroundColor: "#1e1e1e" }}>
      <Container maxWidth="xl">
        {/* Normal Size */}
        <Grid
          container
          sx={{ display: { xs: "none", s: "none", md: "flex", lg: "flex" } }}
        >
          <Grid
            item
            sx={{
              display: "flex",
              textAlign: "left",
              alignItems: "center",
              paddingLeft: 2,
            }}
            xs={12}
            s={12}
            md={6}
            lg={6}
          >
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                mb: 0.1,
              }}
            >
              <img
                style={{ height: "50px", weight: "100%" }}
                src={logo}
                alt="Fermo Tech Solutions Footer"
              />
            </Typography>
            <Typography sx={{fontSize:"15px"}}>
              {"\u00A9"} 2022 Fermo Tech Solutions. All rights reserved.
            </Typography>
          </Grid>

          <Grid
            item
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
            xs={12}
            s={12}
            md={6}
            lg={6}
          >
            <SocialMediaFooter />
          </Grid>
        </Grid>

        {/* Small Display */}
        <Grid
          container
          sx={{
            display: { xs: "flex", s: "flex", md: "none", lg: "none" },
          }}
        >
          <Grid
            item
            xs={12}
            s={12}
            sx={{ textAlign: "center", marginBottom: "30px" }}
          >
            <Typography
            component="a"
              href="/"
            >
            <img
              style={{ height: "100px", weight: "100%" }}
              src={logo}
              alt="Fermo Tech Solutions Footer"
            />
            </Typography>
          </Grid>
          <Grid
            item
            sx={{
              alignItems: "center",
            }}
            xs={12}
            s={12}
          >
            <SocialMediaFooter />
          </Grid>
          <Grid
            item
            sx={{
              textAlign: "center",
              alignItems: "center",
            }}
            xs={12}
            s={12}
          >
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                mb: 0.1,
              }}
            ></Typography>
            <Typography sx={{marginLeft:"-20px",fontSize:"13px"}}>
              {"\u00A9"} 2022 Fermo Tech Solutions. All rights reserved.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
