export const EDUCATION = [
    {
        id:1,
        title: "Coding Boot Camp in MERN Full Stack Development",
        schoolName: "University of California, Irvine Division of Continue Education",
        gradutionYear: 2019,
        icon: "Code"
    },
    {
        id:2,
        title: "Bachelor of Science in Mathematics",
        schoolName: "California State University of Fullerton",
        gradutionYear: 2014,
        icon: "Math"
    },
    {
        id:3,
        title: "Assoicate of Arts Degree",
        schoolName:"Fulleton College",
        gradutionYear: 2009,
        icon: "Math"
    }
]