import EducationBig from "./conponents/EducationBig";
import EducationSmall from "./conponents/EducationSmall";

const Education = () => {
  return (
    <>
      <EducationBig />
      <EducationSmall />
    </>
  );
};

export default Education;
